<i18n>
ru:
  favoritesSticker: Избранное
ua:
  favoritesSticker: Вибране
us:
  favoritesSticker: Favorites
</i18n>

<template>
  <template v-if="appConfig.VueSettingsPreRun.MenuStickersShowInGroups && initiallyThereWasStickers">
    <div class="v-filters-wrapper v-mb-sm v-d-flex">
      <lazy-menu-filters-ingredients-azanulbizar
        v-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Azanulbizar' && tags.length > 0"
        :tags="tags"
      />
      <lazy-menu-filters-ingredients-fangorn
        v-else-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Fangorn' && tags.length > 0"
        :tags="tags"
      />

      <lazy-menu-sorting-products-page />
    </div>
    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="stickers.length > 0"
        class="v-flex-100"
      >
        <lazy-menu-filters-stickers-mirkwood
          v-if="appConfig.VueSettingsPreRun.MenuStickersLayout === 'Mirkwood'"
          :stickers="stickers"
        />
        <lazy-menu-filters-stickers-nan-curunir
          v-if="appConfig.VueSettingsPreRun.MenuStickersLayout === 'NanCurunir'"
          :stickers="stickers"
        />
      </div>
    </transition>
  </template>

  <div
    v-else
    class="v-flex-100 v-d-flex v-flex-row v-justify-content-between v-mb-sm"
  >
    <lazy-menu-filters-ingredients-azanulbizar
      v-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Azanulbizar' && tags.length > 0"
      :tags="tags"
    />
    <lazy-menu-filters-ingredients-fangorn
      v-else-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Fangorn' && tags.length > 0"
      :tags="tags"
    />

    <lazy-menu-sorting-products-page />
  </div>
</template>

<script setup lang="ts">
import { favoriteId, Guid } from '~api/consts'

import type { ItemInList, StickerTag, Tag } from '~types/menuStore'

import { useCommon } from '@arora/common'

const appConfig = useAppConfig()
const accountStore = useAccountStore()
const menuStore = useMenuStore()
const initiallyThereWasStickers = ref<boolean>(false)
const { translate } = useI18nSanitized()
const { dedupeArrayByID } = useCommon()

const { CurrentGroupId, CurrentSubgroupId } = storeToRefs(menuStore)

const onMainPage = computed<boolean>(() => Guid.IsNullOrEmpty(menuStore.CurrentGroupId))

onMounted(async () => {
  if (!Guid.IsNullOrEmpty(menuStore.CurrentGroupId)) await refreshProducts()

  initiallyThereWasStickers.value = stickers.value.length > 0
})

watch(
  () => menuStore.FilterStickers,
  async () => await refreshProducts(),
  { deep: true }
)

watch(
  () => menuStore.FilterTags,
  async () => await refreshProducts(),
  { deep: true }
)

const stickers = ref<StickerTag[]>([])
const tags = ref<Tag[]>([])

async function refreshProducts(): Promise<void> {
  if (onMainPage.value) {
    tags.value = []
    //TODO: handle main page
  } else {
    let stickerTags: StickerTag[] = []
    let tagsLocal: Tag[] = []
    let anyFavorite = false

    const menuListItems = [] as ItemInList[]

    if (menuStore.CurrentSubgroup && menuStore.CurrentSubgroup.IsCommonGroup) {
      const groupsIds = new Set(
        (appConfig.Groups.filter((group) => group.ParentGroupID === CurrentGroupId.value) ?? []).map(
          (group) => group.ID
        )
      )

      for (const product of appConfig.Products) {
        if (groupsIds.has(product.GroupID)) menuListItems.push({ Product: product })
      }
    } else {
      for (const product of appConfig.Products) {
        if (product.GroupID === (CurrentSubgroupId.value ?? CurrentGroupId.value))
          menuListItems.push({ Product: product })
      }
    }

    if (menuListItems.length > 0) {
      const filtered =
        appConfig.VueSettingsPreRun.MenuStickersBehavior === 'exclusive'
          ? menuListItems
          : await menuStore.applyFiltersAndSorting(menuListItems)

      for (const menuItem of filtered) {
        if (menuItem.Product) {
          tagsLocal = [...tagsLocal, ...menuItem.Product.Tags]
          stickerTags = [...stickerTags, ...menuItem.Product.StickerTags]

          anyFavorite = anyFavorite || menuItem.Product.IsFavorite
        }
      }
    }

    if (accountStore.isLoggedIn && anyFavorite) {
      stickerTags.unshift({
        ID: favoriteId,
        Color: '',
        ImageUrl: '',
        Title: translate('filtersPage.favoritesSticker')
      })
    }

    stickers.value = dedupeArrayByID<StickerTag>(stickerTags)
    tags.value = dedupeArrayByID<Tag>(tagsLocal)
  }
}
</script>

<style lang="scss">
@use '~/assets/mixins';

.v-filters-wrapper {
  gap: 8px;
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include mixins.sm {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }
}
</style>
